import { Component } from '@angular/core';
import { BaseInsuranceComponent } from '../base/base.component';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { ResultItemComponent } from '../../result-item/result-item.component';
import { TranslationComponent } from '../../translation/translation.component';

@Component({
  selector: 'app-occupation-insurance',
  standalone: true,
  imports: [NxAccordionModule, ResultItemComponent, TranslationComponent],
  templateUrl: './occupation-insurance.component.html',
  styleUrl: './occupation-insurance.component.css'
})
export class OccupationInsuranceComponent extends BaseInsuranceComponent {

}
