<div nxLayout="grid maxwidth" class="nx-margin-top-2m">
  <form [formGroup]="needformFormGroup">
  <nx-single-stepper>
    <nx-step label="Grundangaben Firma">
      <app-form-step icon="product-bank-monument-info" formGroupName="company">
        <h3>Wie heisst Ihre Firma?</h3>
        <nx-formfield
          label="Firmenname"
          appearance="outline"
          floatLabel="always"
        >
          <input nxInput             formControlName="companyName"
 />
        </nx-formfield>
        <h3>Welche Rechtsform hat Ihr Unternehmen?</h3>
        <nx-formfield
          label="Rechtsform"
          appearance="outline"
          floatLabel="always"
        >
          <nx-dropdown formControlName="companyLegalForm">
            <nx-dropdown-item value="Option 1"></nx-dropdown-item>
            <nx-dropdown-item value="Option 2"></nx-dropdown-item>
            <nx-dropdown-item value="Option 3"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <h3>In welchem Jahr wurde Ihr Unternehmen gegründet?</h3>
        <nx-formfield
          label="Gründungsjahr"
          appearance="outline"
          floatLabel="always"
        >
          <input nxInput type="number" formControlName="foundingYear"/>
          <!-- <input nxDatefield [datepicker]="datepicker" nxInput type="number" formControlName="foundingYear"/> -->
          <!-- <nx-datepicker-toggle -->
          <!--   [for]="datepicker" -->
          <!--   nxFormfieldSuffix -->
          <!-- ></nx-datepicker-toggle> -->
          <!-- <nx-datepicker #datepicker startView="multi-year"></nx-datepicker> -->
        </nx-formfield>
        <h3>In welcher Branche ist Ihr Unternehmen tätig?</h3>
        <nx-formfield label="Branche" appearance="outline" floatLabel="always">
          <nx-dropdown formControlName="industry">
            <nx-dropdown-item value="Option 1"></nx-dropdown-item>
            <nx-dropdown-item value="Option 2"></nx-dropdown-item>
            <nx-dropdown-item value="Option 3"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <div class="action-buttons">
          <button nxButton="secondary" role="button" nxStepperPrevious>
            Zurück
          </button>
          <button nxButton role="button" nxStepperNext>Weiter</button>
        </div>
      </app-form-step>
    </nx-step>
    <nx-step>
      <app-form-step icon="product-clock" formGroupName="employeesActivity">
        <h3>
          Wie viele Mitarbeitenden sind in Ihrem Unternehmen, Sie
          eingeschlossen?
        </h3>
        <nx-formfield label="Anzahl Mitarbeitende" appearance="outline" floatLabel="always">
          <nx-dropdown formControlName="noOfEmployees">
            <nx-dropdown-item value="1"></nx-dropdown-item>
            <nx-dropdown-item value="2-9"></nx-dropdown-item>
            <nx-dropdown-item value="10-49"></nx-dropdown-item>
            <nx-dropdown-item value="50-249"></nx-dropdown-item>
            <nx-dropdown-item value="250_more">250 und mehr</nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <h3>
          Wie hoch war die Gesamtsumme an Löhnen, die Sie letztes Jahr ausbezahlt haben?
        </h3>
        <nx-radio-group name="wagesAmount" formControlName="wagesAmount">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="1_50_mil">1-50 Mio.</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no_answer">keine Angabe</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Wie hoch war Ihr provisorischer Umsatz im letzten Jahr?
        </h3>
        <nx-radio-group name="turnover" formControlName="turnover">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="1_50_mil">1-50 Mio.</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no_answer">keine Angabe</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Transportieren Sie Waren oder lassen Sie Waren transportieren?
        </h3>
        <nx-radio-group name="doTransportGoods" formControlName="doTransportGoods">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">Ja</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">Nein</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <div class="action-buttons">
          <button nxButton="secondary" role="button" nxStepperPrevious>
            Zurück
          </button>
          <button nxButton role="button" nxStepperNext>Weiter</button>
        </div>
      </app-form-step>
    </nx-step>
    <nx-step>
      <app-form-step nextStepLabel="Zur Auswertung" formGroupName="locationEquipment">
        <h3>
            Bezahlen Sie an Ihrem Standort Miete oder ist Ihr Unternehmen Eigentümer des Standorts?
        </h3>
        <nx-radio-group name="rentOrOwn" formControlName="rentOrOwn">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="rent">Zur Miete</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="own">Eigentümer</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Verfügen Sie über mehr als 6 Fahrzeuge (Firmenfahrzeuge)?
        </h3>
        <nx-radio-group name="overSixVehichles" formControlName="overSixVehichles">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">Ja</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">Nein</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Haben Sie mehr Mobiliar als einen Tisch und einen Stuhl?
        </h3>
        <nx-radio-group name="furnitured" formControlName="furnitured">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">Ja</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">Nein</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Beinhaltet Ihre Einrichtung Bürotechnik, Maschinen oder Anlagen?
        </h3>
        <nx-radio-group name="hasMachinery" formControlName="hasMachinery">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">Ja</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">Nein</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Übersteigen die Einrichtungsgegenstände, den Gesamtwert von CHF 5 Mio.?
        </h3>
        <nx-radio-group name="furnitureValue" formControlName="furnitureValue">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">Ja</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">Nein</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <h3>
            Planen Sie demnächst einen Umbau, einen Neubau oder eine Renovation Ihres Standorts?
        </h3>
        <nx-radio-group name="locationExpansion" formControlName="locationExpansion">
          <div nxLayout="grid">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">Ja</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">Nein</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
        <div class="action-buttons">
          <button nxButton="secondary" role="button" nxStepperPrevious>
            Zurück
          </button>
          <button nxButton role="button" (click)="submitForm()">
            Zur Auswertung
          </button>
        </div>
      </app-form-step>
    </nx-step>
  </nx-single-stepper>
  </form>
</div>
