<nx-accordion [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Gebäudeversicherung">
      Sachschäden und Haftpflichtrisiken sind nicht zu unterschätzen. Deshalb ist es als Eigentümerin oder Eigentümer
      eines Gebäudes wichtig, Ihre Immobilie umfassend abzusichern. Unsere <strong>Gebäudeversicherung</strong> für
      Unternehmen übernimmt Schäden an Ihrer Liegenschaft oder Ihren baulichen Anlagen ausserhalb des Gebäudes.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance bâtiments">
      Les risques de dommages matériels et de responsabilité civile ne doivent pas être sous-estimés. Il est important,
      en tant que propriétaire, de bien protéger votre immeuble. Notre <strong>assurance bâtiments</strong> pour les
      entreprises couvre les dommages causés à votre immeuble ou à vos installations extérieures.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione stabili">
      I danni a cose e i rischi di responsabilità civile non devono essere sottovalutati. Per questo, in qualità di
      proprietari di uno stabile, è importante assicurarlo in modo completo. La nostra <strong>assicurazione
        stabili</strong> per le imprese copre i danni alla proprietà immobiliare, compresi quelli alle installazioni
      edili esterne all’edificio.
    </app-result-item>
  </app-translation>
</nx-accordion>
