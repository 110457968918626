<nx-accordion [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Vollversicherung in der Beruflichen Vorsorge">
      Die Angestellten: zufrieden. Die Zukunft: gesichert. Mit unseren <strong>Vollversicherungslösungen</strong> gibt
      es keine bösen Überraschungen. Denn wir übernehmen sämtliche Risiken wie Tod, Invalidität sowie Langlebigkeit –
      und tragen auch das Anlagerisiko. Selbst bei einem schlechten Anlageverlauf gerät Ihre Pensionskasse nie in eine
      Unterdeckung, und Sie müssen somit keine Sanierungsbeiträge leisten.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance complète dans la prévoyance professionnelle">
      Un personnel satisfait, c’est un avenir assuré. Avec nos solutions d’<strong>assurance complète</strong>, vous
      êtes à l’abri des mauvaises surprises. Nous prenons en charge tous les risques (décès, invalidité ou longévité
      compris) et supportons également le risque de placement. Même en cas d'évolution défavorable des placements, votre
      caisse de pensions ne se retrouve jamais en situation de sous-couverture et vous ne devez donc pas payer de
      contributions d’assainissement.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione completa nella previdenza professionale">
      Personale soddisfatto e futuro assicurato. Con le nostre <strong>soluzioni di assicurazione completa</strong> non
      avrete brutte sorprese. Ci assumiamo noi tutti i rischi, come decesso, invalidità e longevità – e anche il rischio
      di investimento. Questo significa che anche in caso di andamento sfavorevole dell’investimento la vostra cassa
      pensioni non potrà mai incorrere in una situazione di copertura insufficiente e voi non dovrete mai versare
      contributi di risanamento.
    </app-result-item>
  </app-translation>
</nx-accordion>
