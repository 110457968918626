import { Component, OnInit } from "@angular/core";
import { NxAccordionModule } from "@aposin/ng-aquila/accordion";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { ResultItemComponent } from "../result-item/result-item.component";
import { LeadFormHostComponent } from "../lead-form-host/lead-form-host.component";
import { NeedformCalculatorService } from "src/app/app/core/services/needform-calculator.service";
import { LiabilityInsuranceComponent } from "../insurances/liability-insurance/liability-insurance.component";
import { CyberInsuranceComponent } from "../insurances/cyber-insurance/cyber-insurance.component";
import { MaterialInsuranceComponent } from "../insurances/material-insurance/material-insurance.component";
import { GeneralInsuranceComponent } from "../insurances/general-insurance/general-insurance.component";
import { BuildingInsuranceComponent } from "../insurances/building-insurance/building-insurance.component";
import { ConstructionInsuranceComponent } from "../insurances/construction-insurance/construction-insurance.component";
import { TechnologyInsuranceComponent } from "../insurances/technology-insurance/technology-insurance.component";
import { TransportInsuranceComponent } from "../insurances/transport-insurance/transport-insurance.component";
import { LegalInsuranceComponent } from "../insurances/legal-insurance/legal-insurance.component";
import { FleetInsuranceComponent } from "../insurances/fleet-insurance/fleet-insurance.component";
import { OccupationInsuranceComponent } from "../insurances/occupation-insurance/occupation-insurance.component";
import { SicknessInsuranceComponent } from "../insurances/sickness-insurance/sickness-insurance.component";
import { AccidentInsuranceComponent } from "../insurances/accident-insurance/accident-insurance.component";

@Component({
  selector: "app-results-page",
  standalone: true,
  imports: [
    NxGridModule,
    NxAccordionModule,
    ResultItemComponent,
    LeadFormHostComponent,
    LiabilityInsuranceComponent,
    CyberInsuranceComponent,
    MaterialInsuranceComponent,
    GeneralInsuranceComponent,
    BuildingInsuranceComponent,
    ConstructionInsuranceComponent,
    TechnologyInsuranceComponent,
    TransportInsuranceComponent,
    LegalInsuranceComponent,
    FleetInsuranceComponent,
    OccupationInsuranceComponent,
    SicknessInsuranceComponent,
    AccidentInsuranceComponent,
  ],
  templateUrl: "./results-page.component.html",
  styleUrl: "./results-page.component.scss",
})
export class ResultsPageComponent implements OnInit {
  insuranceResult: {
    mandatory: string[];
    optional: string[];
  } = { mandatory: [], optional: [] };
  constructor(private calculator: NeedformCalculatorService) {

  }

  ngOnInit(): void {
    this.insuranceResult = this.calculator.getResult()
  }
}
