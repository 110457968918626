<section class="introduction">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12,12,8">
        <h1>Unsere Empfehlung</h1>
        <p>
          Möchten Sie Ihre Ergebnisse mit einer Expertin oder einem Experten
          besprechen? Wir beraten Sie gerne kostenlos und unverbindlich.<br />
          Füllen Sie dazu einfach das Kontaktformular aus.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="recommendations">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12,12,6" class="results">
        <h2>Obligatorisch</h2>
        @for (insurance of insuranceResult.mandatory; track $index) {
          @switch (insurance) {
            @case ('liability') {
              <app-liability-insurance></app-liability-insurance>
            }
            @case('cyber') {
              <app-cyber-insurance></app-cyber-insurance>
            }
            @case('material') {
              <app-material-insurance></app-material-insurance>
            }
            @case('general') {
              <app-general-insurance></app-general-insurance>
            }
            @case('building') {
              <app-building-insurance></app-building-insurance>
            }
            @case('construction') {
              <app-construction-insurance></app-construction-insurance>
            }
            @case('technology') {
              <app-technology-insurance></app-technology-insurance>
            }
            @case('transport') {
              <app-transport-insurance></app-transport-insurance>
            }
            @case('legal') {
              <app-legal-insurance></app-legal-insurance>
            }
            @case('fleet') {
              <app-fleet-insurance></app-fleet-insurance>
            }
            @case('occupational') {
              <app-occupation-insurance></app-occupation-insurance>
            }
            @case('sickness') {
              <app-sickness-insurance></app-sickness-insurance>
            }
            @case('accident') {
              <app-accident-insurance></app-accident-insurance>
            }
          }
        }
        <h2>Optional</h2>
        @for (insurance of insuranceResult.optional; track $index) {
          @switch (insurance) {
            @case ('liability') {
              <app-liability-insurance [optional]="true"></app-liability-insurance>
            }
            @case('cyber') {
              <app-cyber-insurance [optional]="true"></app-cyber-insurance>
            }
            @case('material') {
              <app-material-insurance [optional]="true"></app-material-insurance>
            }
            @case('general') {
              <app-general-insurance [optional]="true"></app-general-insurance>
            }
            @case('building') {
              <app-building-insurance [optional]="true"></app-building-insurance>
            }
            @case('construction') {
              <app-construction-insurance [optional]="true"></app-construction-insurance>
            }
            @case('technology') {
              <app-technology-insurance [optional]="true"></app-technology-insurance>
            }
            @case('transport') {
              <app-transport-insurance [optional]="true"></app-transport-insurance>
            }
            @case('legal') {
              <app-legal-insurance [optional]="true"></app-legal-insurance>
            }
            @case('fleet') {
              <app-fleet-insurance [optional]="true"></app-fleet-insurance>
            }
            @case('occupational') {
              <app-occupation-insurance [optional]="true"></app-occupation-insurance>
            }
            @case('sickness') {
              <app-sickness-insurance [optional]="true"></app-sickness-insurance>
            }
            @case('accident') {
              <app-accident-insurance [optional]="true"></app-accident-insurance>
            }
          }
        }
      </div>
      <div nxCol="12,12,6" class="leadform">
        <app-lead-form-host></app-lead-form-host>
      </div>
    </div>
  </div>
</section>
