import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-insurance',
  standalone: true,
  imports: [],
  template: '',
})
export abstract class BaseInsuranceComponent {
  @Input() optional = false
}
