<nx-accordion [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Krankentaggeldversicherung">
      Als Arbeitgeberin oder Arbeitgeber sind Sie gesetzlich verpflichtet, den Lohn weiterzuzahlen (OR 324a). Bei einer
      krankheitsbedingten Abwesenheit ist die Lohnfortzahlung zwar zeitlich begrenzt, aber wenn Sie nicht ausreichend
      versichert sind, kann das dennoch zur Belastung werden. Mit unserer <strong>Krankentaggeldversicherung</strong>
      sind Sie auf der sicheren Seite.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance Indemnités journalières en cas de maladie">
      En tant qu’employeur, vous avez l’obligation légale de continuer à verser son salaire à un employé absent pour
      cause de maladie (art. 324a CO). La poursuite du versement du salaire en cas de maladie est certes limitée dans le
      temps, mais elle peut entraîner une charge financière importante si vous ne bénéficiez pas d’une assurance
      adéquate. Avec notre <strong>assurance indemnités journalières en cas de maladie</strong>, vous misez sur la
      sécurité.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione di indennità giornaliera di malattia">
      Per legge il datore di lavoro ha l’obbligo di continuare a pagare il salario (art. 324a CO). Se l’assenza dal
      lavoro è dovuta a malattia, la prosecuzione del pagamento dello stipendio ha sì una durata limitata, ma se non si
      è sufficientemente assicurati i costi possono risultare comunque gravosi. Con la nostra <strong>assicurazione di
        indennità giornaliera per malattia</strong> siete tutelati al meglio.
    </app-result-item>
  </app-translation>
</nx-accordion>
