<nx-multi-stepper
  #stepper
  [linear]="true"
  currentStepLabel="Step"
  (selectionChange)="updateStepper($event)"
>
  <nx-step
    [stepControl]="stepThreeForm"
    label="{{ 'copy.step_3.label' | translate }}"
  >
    <form [formGroup]="stepThreeForm">
      <div nxRow>
        <div nxCol="12">
          <h2 class="form-headline">Kontaktformular</h2>
        </div>
        <div class="nx-margin-bottom-2m" nxCol="12,12,12" colOffset="0">
          <nx-formfield
            label="{{ 'core.label.firstname' | translate }}, {{
              'core.label.lastname' | translate
            }}"
            appearance="outline"
            floatLabel="always"
          >
            <input formControlName="firstname" nxInput required />
          </nx-formfield>
        </div>
        <div class="nx-margin-bottom-2m" nxCol="12,12,12,6" colOffset="0">
          <nx-formfield
            label="{{ 'core.label.email' | translate }}"
            appearance="outline"
            floatLabel="always"
          >
            <input formControlName="email" nxInput type="email" />
          </nx-formfield>
        </div>
        <div class="nx-margin-bottom-2m" nxCol="12,12,12,6" colOffset="0">
          <nx-formfield appearance="outline" floatLabel="always">
            <nx-formfield-label>{{
              "core.label.phonenumber" | translate
            }}</nx-formfield-label>
            <nx-phone-input
              formControlName="phone"
              countryCode="CH"
            ></nx-phone-input>
          </nx-formfield>
        </div>
        <div
          class=""
          nxCol="12,12,12"
          colOffset="0"
          [hidden]="isLeadGeneratorLink"
        >
          <nx-formfield
            label="{{ 'core.label.zip' | translate }}"
            appearance="outline"
            floatLabel="always"
          >
            <!--                  <input formControlName="zip" nxInput (change)="changeZip()"/>-->
            <input formControlName="zip" nxInput (change)="onZipChange()" />
            <span nxFormfieldPrefix>CH-</span>
          </nx-formfield>
        </div>
        <div class="nx-margin-bottom-4m" nxCol="12" colOffset="0">
          <nx-checkbox-group formControlName="availability">
            <nx-label size="small">{{
              "core.label.availability" | translate
            }}</nx-label>
            <div class="horizontal-checkboxes">
              <nx-checkbox value="8-12 Uhr" class="">{{
                "core.label.time_1" | translate
              }}</nx-checkbox>
              <nx-checkbox value="12-13 Uhr" class=""
                >{{ "core.label.time_2" | translate }}
              </nx-checkbox>
              <nx-checkbox value="13-17 Uhr" class=""
                >{{ "core.label.time_3" | translate }}
              </nx-checkbox>
              <nx-checkbox value="17-20 Uhr" class=""
                >{{ "core.label.time_4" | translate }}
              </nx-checkbox>
            </div>
          </nx-checkbox-group>
        </div>
      </div>
    </form>
    <div class="nx-margin-top-xl" nxRow>
      <div class="u-text-center" nxCol="12" colOffset="0">
        <button
          nxButton="primary"
          (click)="onsubmit($event)"
          type="button"
          class="submit-button"
        >
          {{ "core.button.submit" | translate }}
        </button>
      </div>
      <div nxCol="12,12">
        <p
          class="nx-margin-top-2xs legal-notice"
          nxCopytext="medium"
          [innerHtml]="'app.form.legal' | translate"
        ></p>
      </div>
    </div>
  </nx-step>
  <nx-step label="{{ 'copy.step_4.label' | translate }}">
    <div class="nx-margin-top-l" nxRow>
      <div
        class="nx-margin-bottom-2m nx-margin-top-4m u-text-center"
        nxCol="12"
        colOffset="0"
      >
        <nx-icon
          name="check"
          fill="true"
          outline="true"
          size="m"
          style="background: #3da556"
        ></nx-icon>
      </div>
      <div class="nx-margin-bottom-2m u-text-center" nxCol="12" colOffset="0">
        <h2 nxHeadline="section">{{ "success.headline" | translate }}</h2>
      </div>
      <div class="nx-margin-bottom-5xl u-text-center" nxCol="8" colOffset="2">
        <p nxCopytext="large">
          {{ "success.copy" | translate }}
        </p>
      </div>
    </div>
  </nx-step>
</nx-multi-stepper>
