import { Injectable } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class NeedformCalculatorService {
  private formGroup = this.fb.group({
    company: this.fb.group({
      companyName: [""],
      companyLegalForm: [""],
      foundingYear: new FormControl<number | null>(null),
      industry: [""],
    }),
    employeesActivity: this.fb.group({
      noOfEmployees: [""],
      wagesAmount: [""],
      turnover: [""],
      doTransportGoods: [""],
    }),
    locationEquipment: this.fb.group({
      rentOrOwn: [""],
      overSixVehichles: [""],
      furnitured: [""],
      hasMachinery: [""],
      furnitureValue: [""],
      locationExpansion: [""],
    }),
  });
  constructor(private fb: FormBuilder) { }

  getResult() {
    /*
     * liability	cyber	material	general	building	construction	technology	transport	legal	fleet	occupational	sickness	accident	XXXX
     */
    const mandatory = new Set(["liability", "legal"]);
    const optional = new Set<string>();
    const updateInsurance = function <T>(name: string, answer: T, compare: T) {
      if (answer === compare) {
        mandatory.add(name);
      } else {
        optional.add(name);
      }
    };
    const answers = this.formGroup.value;
    updateInsurance("cyber", answers.locationEquipment?.hasMachinery, "yes");
    updateInsurance("material", answers.locationEquipment?.furnitured, "yes");
    updateInsurance(
      "general",
      answers.locationEquipment?.furnitureValue,
      "yes",
    );
    updateInsurance("building", answers.locationEquipment?.rentOrOwn, "own");
    updateInsurance(
      "construction",
      answers.locationEquipment?.locationExpansion,
      "yes",
    );
    updateInsurance(
      "technology",
      answers.locationEquipment?.hasMachinery,
      "yes",
    );
    updateInsurance(
      "transport",
      answers.employeesActivity?.doTransportGoods,
      "yes",
    );
    updateInsurance(
      "fleet",
      answers.locationEquipment?.overSixVehichles,
      "yes",
    );

    if (
      ["2-9", "10-49", "50-249"].includes(
        answers.employeesActivity && answers.employeesActivity.noOfEmployees
          ? answers.employeesActivity.noOfEmployees
          : "",
      )
    ) {
      mandatory.add("occupational");
      mandatory.add("sickness");
      mandatory.add("accident");
    } else {
      optional.add("occupational");
      optional.add("sickness");
      optional.add("accident");
    }

    return {
      mandatory: Array.from(mandatory).sort(),
      optional: Array.from(optional).sort(),
    };
  }

  getFormGroup() {
    console.log(this.formGroup);
    return this.formGroup;
  }
}
