import { Component } from "@angular/core";
import { LeadFormComponent } from "../lead-form/lead-form.component";

@Component({
  selector: "app-lead-form-host",
  standalone: true,
  imports: [LeadFormComponent],
  templateUrl: "./lead-form-host.component.html",
  styleUrl: "./lead-form-host.component.scss",
})
export class LeadFormHostComponent {}
