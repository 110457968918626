import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NxCheckboxModule } from "@aposin/ng-aquila/checkbox";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { NxPhoneInputComponent } from "@aposin/ng-aquila/phone-input";
import {
  NxMultiStepperComponent,
  NxStepComponent,
} from "@aposin/ng-aquila/progress-stepper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
  Agency,
  AgencyListService,
} from "src/app/core/services/agency-list.service";
import { TrackingService } from "src/app/core/services/trackingservice/tracking.service";
import countries from "i18n-iso-countries";
import {
  SourceResult,
  SourcesService,
} from "src/app/core/services/sources/sources.service";
import { AppDatastore } from "src/app/core/services/app-store/app.datastore";
import { environment } from "src/environments/environment";
import { ElcaService, LeadData } from "src/app/core/services/elca/elca.service";
import { TemplateRendererService } from "src/app/core/services/template-renderer-service/template-renderer.service";
import { NewLeadComponent } from "src/app/mail-templates/new-lead/new-lead.component";
import mailShell from "src/app/core/services/template-renderer-service/mail-shell";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxButtonModule } from "@aposin/ng-aquila/button";

@Component({
  selector: "app-lead-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NxMultiStepperComponent,
    NxStepComponent,
    NxFormfieldModule,
    NxPhoneInputComponent,
    NxCheckboxModule,
    NxIconModule,
    NxInputModule,
    TranslateModule,
    NxButtonModule,
  ],
  templateUrl: "./lead-form.component.html",
  styleUrl: "./lead-form.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class LeadFormComponent implements OnInit {
  readonly stepThreeForm = this.fb.group({
    zip: [
      "",
      [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern("\\d*"),
      ],
    ],
    email: ["", [Validators.required, Validators.email]],
    phone: ["+41", [Validators.required, Validators.minLength(12)]],
    availability: "",
    firstname: ["", Validators.required],
  });
  isLeadGeneratorLink = false;
  agencies!: Agency[];
  showDebug = false;
  translatedCountries!: countries.LocalizedCountryNames<{ select: "official" }>;
  decodedEmail = "";
  externalLink = "";

  private campaign = "default";
  @ViewChild("stepper") private myStepper!: NxMultiStepperComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    private fb: UntypedFormBuilder,
    private agencyListService: AgencyListService,
    private translateService: TranslateService,
    private appDataStore: AppDatastore,
    private sourcesService: SourcesService,
    private elcaService: ElcaService,
    private templateRenderer: TemplateRendererService,
  ) {}

  ngOnInit(): void {
    this.getAgencyList();
    this.isLeadGeneratorLink = false;
    let langX = "";
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["debug"]) {
        this.showDebug = params["debug"];
      }
      if (params["lang"]) {
        this.translatedCountries = countries.getNames(params["lang"], {
          select: "official",
        });
        this.trackingService.appLanguage = params["lang"];
      } else {
        this.translatedCountries = countries.getNames("de", {
          select: "official",
        });
        this.trackingService.appLanguage = "de";
      }
      if (params["data_zp"]) {
        this.decodedEmail = atob(params["data_zp"]);
        this.isLeadGeneratorLink = true;
        this.stepThreeForm.controls["zip"].clearValidators();
        this.stepThreeForm.controls["zip"].updateValueAndValidity();
      }
      if (params["d"]) {
        this.decodedEmail = atob(params["d"]);
        this.isLeadGeneratorLink = true;
        this.stepThreeForm.controls["zip"].clearValidators();
        this.stepThreeForm.controls["zip"].updateValueAndValidity();
        // we set the lead 'origin' (evar211) to indicate a lead from the leadgenerator.allianz.ch tool
        //TODO: how do we handle other variants like WSA etc.
        this.trackingService.ls_origin = "LeadByYou";
      }
      if (params["cp"]) {
        this.campaign = params["cp"];
      }
      // external link param for paid media adds overwriting source.url attribute (tutti etc.)
      if (params["extLnk"]) {
        this.externalLink = params["extLnk"];
      }

      if (params["ga"]) {
        this.trackingService.ls_gaID = params["ga"];
        // Agency have been preselected
        // Preload the translations files for this agency
        // We use getList() -> Observable<Agency[]> function so that we are sure that data from agency.json
        // are loaded when we try to get Agency and it's language
        this.agencyListService.getList().subscribe((_) => {
          const agencies = this.agencyListService.getById(params["ga"]);
          for (const agency of agencies) {
            this.addAgencyLanguage(agency);
          }
        });
      }
      //TODO: Refactor analytics tracking
      langX = params["lang"];
      if (typeof langX == "undefined") {
        langX = "de";
      }
    });
    this.trackingService.trackStep("start", langX);
  }

  updateStepper($event: StepperSelectionEvent) {
    // forgive me father for my sins...
    //TODO: Refactor this POS tracking implementation asap
    let lang = "";
    this.activatedRoute.queryParams.subscribe((params) => {
      lang = params["lang"];
    });
    if (typeof lang == "undefined") {
      lang = "de";
    }

    switch ($event.selectedIndex) {
      case 0: {
        this.trackingService.trackStep("start", lang);
        break;
      }
      case 1: {
        this.trackingService.trackStep("success", lang);
        // TODO: move it to tracking service instead of directly calling _satellite function
        // @ts-expect-error we are using vendor variable here which might be missing if not loaded by this time but not necessarily.
        _satellite.track("DCR: Consultation Complete");
        break;
      }
      default: {
        break;
      }
    }
  }

  onZipChange() {
    const agenciesWithZip = this.agencyListService.getByZip(
      this.stepThreeForm.value.zip,
    );
    const availableLanguages = [
      ...Object.keys(this.translateService.translations),
    ];
    for (const agency of agenciesWithZip) {
      this.translateService.reloadLang(agency.language.toLowerCase());
      this.translateService.currentLang = agency.language.toLowerCase();
      if (!availableLanguages.includes(agency.language.toLowerCase())) {
        this.translateService.reloadLang(agency.language.toLowerCase());
        availableLanguages.push(agency.language.toLowerCase());
        this.translateService.currentLang = agency.language.toLowerCase();
        this.addAgencyLanguage(agency);
      }
    }
  }

  public onsubmit(_event: Event): void {
    const formData = new FormData();
    let agency: Agency;
    let source: SourceResult | undefined;

    // leadForm has been called via a leadgenerator leadlink with hashed email and GA/Campaign-ID
    if (this.decodedEmail !== "") {
      this.isLeadGeneratorLink = true;
      // determine agency attributes for tracking by GA-ID
      agency = this.agencyListService.getById(this.trackingService.ls_gaID)[0];
    } else {
      // determine agency tracking attributes by zip from input-field
      agency = this.agencyListService.getByZip(this.stepThreeForm.value.zip)[0];
    }

    // determine routing email
    if (this.isLeadGeneratorLink) {
      // base64 hashed email from lead-generator lead-link
      formData.append("email", this.decodedEmail);
      this.stepThreeForm.controls["zip"].clearValidators();
      this.stepThreeForm.controls["zip"].updateValueAndValidity();
    } else {
      if (
        this.stepThreeForm.value.zip !== "9999" &&
        this.stepThreeForm.value.zip !== "9998" &&
        this.stepThreeForm.value.zip !== "9997" &&
        this.stepThreeForm.value.zip !== "9996"
      ) {
        // email determined by zip code (GA-Mail)
        formData.append("email", agency.email);
      } else {
        // test mail with zip 9999
        formData.append("email", this.stepThreeForm.value.email);
      }
    }
    // always bcc our internal mailbox for each lead generated
    formData.append("bcc", "leadswettbewerb@allianz.ch");

    // update analytics datalayer
    this.trackingService.ls_gaID = agency.id;

    this.appDataStore.userPlz = this.isLeadGeneratorLink
      ? agency.plz[0]
      : this.stepThreeForm.value.zip;

    this.trackingService.trackAgent();

    // We save the application language and change the currentLanguage to the agency language
    // This is to retrieve the agency local subject for the email we are gonna send
    // The agency language translations are hopefully loaded by now here
    // The translations might not be loaded if agency language is different then application language
    // and user is on slower network or user clcks Submit button right after entering their zipCode
    // then we restore the application language.
    // Doing this by using `set currentLang(lang)` doesn't cause application to rerender
    // with updated language so it's perfect for getting instant value of translation
    // Better way would be to ise Observables on template Rendere (not implemented) and on translation service
    // before we send the email.
    const currentLanguage = this.translateService.currentLang;
    this.translateService.currentLang = agency.language.toLowerCase();
    const subject = this.translateService.instant("mail.subject");

    // in case an 'extLnk' query param has been submitted to this form we overwrite the source.url
    source = this.sourcesService.getSource(
      environment.applicationId,
      this.campaign,
    );
    this.translateService.currentLang = currentLanguage;

    // prepare payload for L4Y-BE
    const leadData: LeadData = new LeadData(
      this.stepThreeForm.value.firstname,
      this.isLeadGeneratorLink
        ? Number(agency.plz[0])
        : Number(this.stepThreeForm.value.zip),
      this.stepThreeForm.value.phone,
      this.stepThreeForm.value.email,
      "", //TODO: FLUX
      "", //TODO: FLUX
      "", //TODO: FLUX
      "", //LeadForwarding "Sender" field
      source?.origin ?? "",
      agency.id, //TODO: FLUX?
      "",
      environment.applicationId,
      source?.source ?? "",
      this.campaign,
      source?.category ?? "",
    );

    // send leadData to L4Y-Backend
    this.elcaService.sendLead({ leadData }).subscribe();

    // data for the lead-mail (customer input and source mapping)
    if (!source)
      source = {
        was: "",
        woher: "",
        url: "",
        origin: "",
        source: "",
        category: "",
      };
    const templateData = {
      name: this.stepThreeForm.value.firstname,
      zipCode: this.stepThreeForm.value.zip,
      emailAddress: this.stepThreeForm.value.email,
      phoneNumber: this.stepThreeForm.value.phone,
      availability: this.stepThreeForm.value.availability,
      source: source,
      quelle: this.trackingService.ls_src,
      lang: agency.language,
      customerLang: this.trackingService.appLanguage.toUpperCase(),

      leadApp: environment.applicationId,
      leadDate: new Date().toLocaleString("de-CH"),
      campaign: this.campaign,
      environment: environment.stage.toUpperCase(),
    };

    let emailContent = this.templateRenderer.render(
      NewLeadComponent,
      templateData,
    );
    emailContent = mailShell.replace("{{mailcontent}}", emailContent);

    formData.append("content", emailContent);
    formData.append("subject", subject);

    // legacy php script with header and footer components
    // const url = "https://wfz-2023.lespals.com/sendmail_2024.php";
    const url = environment.mailServer;
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onload = function () {};
    xhr.send(formData);
    this.myStepper.next();
  }

  private getAgencyList() {
    this.agencyListService
      .getList()
      .subscribe((list: Agency[]) => (this.agencies = list));
  }

  /**
   * Preloads missing language translation files for the agency
   * so the translations can be used when generating lead email
   *
   * @param agency agency for which the language files will be preloaded
   */
  private addAgencyLanguage(agency: Agency) {
    const availableLanguages = [
      ...Object.keys(this.translateService.translations),
    ];
    if (!availableLanguages.includes(agency.language.toLowerCase())) {
      this.translateService.reloadLang(agency.language.toLowerCase());
      availableLanguages.push(agency.language.toLowerCase());
    }
  }
}
