export class TrackingObject {
  application: Application;
  page: Page;
  product: Product;

  constructor() {
    this.application = new Application();
    this.page = new Page();
    this.product = new Product();
  }
}

export class Application {
  applicationId: string;
  applicationName: string;
  virtualPageName: string;
  startType: string;
  step: Step;

  constructor() {
    this.applicationId = "";
    this.applicationName = "";
    this.virtualPageName = "";
    this.startType = "";
    this.step = new Step();
  }
}

export class Page {
  pageInfo: PageInfo;

  constructor() {
    this.pageInfo = new PageInfo();
  }
}

export class Step {
  type: string;

  constructor() {
    this.type = "";
  }
}

export class PageInfo {
  fullURL: string;
  hostname: string;
  language: string;
  pageHash: string | null;
  pageName: string;
  URLqueryParams: string;

  constructor() {
    this.fullURL = "";
    this.hostname = "";
    this.language = "";
    this.pageHash = "";
    this.pageName = "";
    this.URLqueryParams = "";
  }
}
export class Product {
  primaryCategory: string;
  subCategory: string;

  constructor() {
    this.primaryCategory = "";
    this.subCategory = "";
  }
}
