<nx-accordion [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Betriebs- und Berufshaftpflichtversicherung">
      Sie können kaum alles in Ihrem Unternehmen kontrollieren. Aber Sie können dafür sorgen, dass Ihr Vermögen bei
      Schadenersatzansprüchen Dritter geschützt ist. Mit unserer <strong>Betriebs- und
        Berufshaftpflichtversicherung</strong> bleiben Sie handlungsfähig. Schliesslich sollen Sie sich ganz auf das
      konzentrieren, was Sie am besten können: Ihr Unternehmen führen.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance responsabilité civile entreprises et responsabilité civile professionnelle">Dans
      votre entreprise, vous pouvez difficilement tout contrôler, mais vous pouvez faire en sorte que vos actifs soient
      protégés en cas de prétentions en dommages-intérêts de tiers. Grâce à notre <strong>assurance responsabilité
        civile entreprises et responsabilité civile professionnelle</strong>, vous conservez votre capacité d’exercice.
      Parce que le plus important, c’est que vous puissiez vous concentrer sur ce que vous savez faire de mieux: diriger
      une entreprise.</app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Responsabilità civile aziendale e professionale">
      Non potete controllare tutto ciò che accade nella vostra azienda, ma potete far sì che il patrimonio sia protetto
      da eventuali pretese di risarcimento danni da parte di terzi. Con la nostra <strong>assicurazione di
        responsabilità civile aziendale e professionale</strong> mettete al sicuro l’operatività. Alla fin fine dovete
      concentrarvi unicamente su ciò che vi riesce meglio: guidare la vostra azienda.
    </app-result-item>
  </app-translation>
</nx-accordion>
