<nx-accordion [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="All Risk">
      Mit unserer <strong>All Risks Sachversicherung</strong> für Unternehmen sind zudem nicht nur alle Ihre Sachwerte,
      sondern auch der Betriebsertrag gegen sämtliche Eventualitäten geschützt.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="All Risk">
      Avec notre <strong>assurance de choses All Risks</strong> pour les entreprises, non seulement tous vos biens
      matériels, mais aussi votre produit d’exploitation sont couverts, quoi qu’il arrive.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="All Risk">
      La nostra <strong>assicurazione di cose All Risks</strong> per le imprese non protegge solo tutti i vostri beni
      mobili ma tutela da ogni eventualità anche i vostri ricavi.
    </app-result-item>
  </app-translation>
</nx-accordion>
