<nx-accordion [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Technikversicherung">
      Einmal die Handbremse nicht angezogen, und schon macht sich der Radlader selbstständig. Eine Spannungsschwankung,
      und der Server fällt aus. Gut, dass unsere <strong>Technikversicherung</strong> genauso intelligent ist wie Ihre
      Geräte und Maschinen. Sie springt ein, wenn etwas beschädigt, zerstört oder gestohlen wird – ganz nach Ihren
      Bedürfnissen.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance équipements techniques">
      Vous avez oublié le frein à main, et voilà la chargeuse sur roues hors de contrôle. Une petite variation de
      tension, et le serveur tombe en panne. Heureusement, notre <strong>assurance équipements techniques</strong> est
      aussi intelligente que vos appareils et machines. Taillée sur mesure pour vos besoins, elle s’occupe de tout en
      cas de détérioration, de destruction ou de vol.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione attrezzature tecniche">
      Avete dimenticato il freno a mano e l’escavatrice se n’è andata per conto suo? C’è stato uno sbalzo di tensione e
      il server è fuori uso? Cose che succedono. Per fortuna la nostra <strong>assicurazione attrezzature
        tecniche</strong> è intelligente tanto quanto le vostre attrezzature e macchine. Tagliata sulle vostre
      specifiche esigenze, interviene in caso di danneggiamento, distruzione o furto.
    </app-result-item>
  </app-translation>
</nx-accordion>
