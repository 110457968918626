import { Component } from '@angular/core';
import { BaseInsuranceComponent } from '../base/base.component';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { ResultItemComponent } from '../../result-item/result-item.component';
import { TranslationComponent } from '../../translation/translation.component';

@Component({
  selector: 'app-sickness-insurance',
  standalone: true,
  imports: [NxAccordionModule, ResultItemComponent, TranslationComponent],
  templateUrl: './sickness-insurance.component.html',
  styleUrl: './sickness-insurance.component.css'
})
export class SicknessInsuranceComponent extends BaseInsuranceComponent {

}
