import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ElcaService {
  public client = environment.client;

  constructor(private http: HttpClient) {}
  //TODO: Specify explicitly expected response
  // eslint-disable-next-line
  sendLead({ leadData }: { leadData: LeadData }): Observable<any> {
    //const params = new HttpParams(leadData);
    //const params = new HttpParams().set('',JSON.stringify(leadData));
    const httpHeaders = new HttpHeaders({
      "X-Api-Key": environment.elcaLeadApiSecret,
      "Content-Type": "application/json",
    });
    return this.http
      .post<LeadData>(environment.elcaLeadsApi, JSON.stringify(leadData), {
        headers: httpHeaders,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred with Elca LeadApi:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () =>
        new Error(
          "Something bad happened with Elca LeadApi; please try again later.",
        ),
    );
  }
}

export class LeadData implements ILeadData {
  firstLastName: string;
  plz: number;
  phoneNumber: string;
  email: string;
  hnumber: string;
  policyNumber: string;
  agent: string;
  sender: string;
  origin: string;
  agency: string;
  customerType: string;
  applicationName: string;
  campaignSource: string;
  campaign: string;
  category: string;

  constructor(
    firstLastName: string,
    plz: number,
    phoneNumber: string,
    email: string,
    hnumber: string,
    policyNumber: string,
    agent: string,
    sender: string, //From LeadForwarding App -> "Sender" Freetext field who submitted the lead
    origin: string,
    agency: string,
    customerType: string,
    applicationName: string,
    campaignSource: string,
    campaign: string,
    category: string,
  ) {
    this.firstLastName = firstLastName;
    this.plz = plz;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.hnumber = hnumber;
    this.policyNumber = policyNumber;
    this.agent = agent;
    this.sender = sender;
    this.origin = origin;
    this.agency = agency;
    this.customerType = customerType;
    this.applicationName = applicationName;
    this.campaignSource = campaignSource;
    this.campaign = campaign;
    this.category = category;
  }
}

export interface ILeadData {
  firstLastName: string;
  plz: number;
  phoneNumber: string;
  email: string;
  hnumber: string;
  policyNumber: string;
  agent: string;
  sender: string;
  origin: string;
  agency: string;
  customerType: string;
  applicationName: string;
  campaignSource: string;
  campaign: string;
  category: string;
}
